<template>
  <svg
    class="mr-2"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#F4C10B" height="19" rx="9.5" width="19" x="0.5" y="0.5" />
    <path
      d="M6 10C6 9.82323 6.07024 9.65366 6.19526 9.52864C6.32029 9.40361 6.48986 9.33337 6.66667 9.33337H13.3333C13.5101 9.33337 13.6797 9.40361 13.8047 9.52864C13.9298 9.65366 14 9.82323 14 10C14 10.1769 13.9298 10.3464 13.8047 10.4714C13.6797 10.5965 13.5101 10.6667 13.3333 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10Z"
      fill="#FAFDFE"
    />
    <rect height="19" rx="9.5" stroke="#F4C10B" width="19" x="0.5" y="0.5" />
  </svg>
</template>
