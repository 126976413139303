<template>
  <svg
    class="mr-2"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#F5260A" height="19" rx="9.5" width="19" x="0.5" y="0.5" />
    <path
      d="M4.862 14.1953L9.05733 9.99997L4.862 5.80464C4.74056 5.6789 4.67336 5.5105 4.67488 5.33571C4.6764 5.16091 4.74652 4.9937 4.87012 4.87009C4.99373 4.74649 5.16093 4.67638 5.33573 4.67486C5.51053 4.67334 5.67893 4.74053 5.80467 4.86197L10 9.05731L14.1953 4.86197C14.2568 4.7983 14.3304 4.74751 14.4117 4.71257C14.4931 4.67763 14.5805 4.65924 14.6691 4.65847C14.7576 4.6577 14.8454 4.67457 14.9273 4.70809C15.0092 4.74161 15.0837 4.79111 15.1463 4.85371C15.2089 4.9163 15.2584 4.99074 15.2919 5.07267C15.3254 5.1546 15.3423 5.24239 15.3415 5.33091C15.3407 5.41943 15.3223 5.50691 15.2874 5.58824C15.2525 5.66958 15.2017 5.74314 15.138 5.80464L10.9427 9.99997L15.138 14.1953C15.2017 14.2568 15.2525 14.3304 15.2874 14.4117C15.3223 14.493 15.3407 14.5805 15.3415 14.669C15.3423 14.7576 15.3254 14.8453 15.2919 14.9273C15.2584 15.0092 15.2089 15.0836 15.1463 15.1462C15.0837 15.2088 15.0092 15.2583 14.9273 15.2919C14.8454 15.3254 14.7576 15.3422 14.6691 15.3415C14.5805 15.3407 14.4931 15.3223 14.4117 15.2874C14.3304 15.2524 14.2568 15.2016 14.1953 15.138L10 10.9426L5.80467 15.138C5.67893 15.2594 5.51053 15.3266 5.33573 15.3251C5.16093 15.3236 4.99373 15.2535 4.87012 15.1299C4.74652 15.0062 4.6764 14.839 4.67488 14.6642C4.67336 14.4894 4.74056 14.321 4.862 14.1953Z"
      fill="#FAFDFE"
    />
    <rect height="19" rx="9.5" stroke="#F5260A" width="19" x="0.5" y="0.5" />
  </svg>
</template>
