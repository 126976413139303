<template>
  <v-textarea
    v-limit-characters="params.max"
    variant="outlined"
    v-model="answerEssay"
    class="w-full"
  >
    <template #label>
      <span class="!text-[#808080]">Resposta</span>
    </template>
  </v-textarea>
  <div class="flex justify-between text-xs mt-5">
    <div>
      <span
      ><span class="font-bold">{{ params.min }}-{{ params.max }}</span> caracteres</span
      >
    </div>
    <div>
      <span class="mr-[24px]"
      >Caracteres:<span class="font-bold">{{ answerEssay.length }}</span></span
      >
      <span
      >Palavras:<span class="font-bold">{{ answerEssay.split(' ').length }}</span></span
      >
    </div>
  </div>
  <div>
    <DragAndDropImageFiles @essay-response="essayFile"/>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { ref, toRefs } from 'vue'
import DragAndDropImageFiles from '@/components/draggable/DragAndDropImageFiles.vue'

const props = defineProps({
  answer: {
    type: String,
    required: true,
    default: ''
  },
  params: {
    type: Object,
    required: false,
    default: () => ({ min: 0, max: 1000 })
  }
})

const { answer, params } = toRefs(props)

const emit = defineEmits(['answer'])

const answerEssay = ref(answer.value)

const selectAnswer = (essay: string) => {
  answerEssay.value = essay
  emit('answer', [essay])
}

const essayFile = (essay: string) => selectAnswer(essay)

watch(answerEssay, (value) => selectAnswer(value))
</script>