<template>
  <v-row v-show="isShowResolution" no-gutters>
    <v-col cols="12" md="9">
      <v-row>
        <v-col cols="12">
          <v-sheet
            :class="{
              'py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet': !mobile,
              'py-[20px] px-[24px] rounded-xl shadow-sheet': mobile
            }"
          >
            <v-main>
              <div :style="fontXl" class="mb-[16px] font-medium">
                {{ data.application?.name }}
              </div>
              <div>
                <h2 :style="fontBase" class="font-bold">Instruções</h2>
                <p :style="fontSm" v-html="data.application?.instructions"></p>
              </div>
            </v-main>
          </v-sheet>
        </v-col>
        <template v-for="subFormQuestions of data.questions" :key="subFormQuestions.subFormOrder">
          <v-col
            v-for="(question, index) of (collect(subFormQuestions.questions) as any)
            .sortBy('order')
            .all()"
            :id="question.id"
            :key="question.id"
            class="question-active"
            cols="12"
          >
            <v-sheet
              :class="{
                'py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet': !mobile,
                'py-[20px] px-[24px] rounded-xl shadow-sheet': mobile
              }"
            >
              <v-main>
                <div class="flex items-center space-x-3 mb-3">
                  <div>
                    <v-chip
                      :style="fontSm"
                      class="!font-medium"
                      color="blue"
                      rounded
                      variant="elevated"
                    >{{ index + 1 }}
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                      :style="fontSm"
                      class="!font-medium"
                      color="blue"
                      rounded
                      variant="elevated"
                    >{{ getQuestionType(question.type) }}
                    </v-chip>
                  </div>
                  <div>
                    <v-tooltip :text="getQuestionDescription(question.type)">
                      <template v-slot:activator="{ props }">
                        <v-chip class="!font-medium" color="blue" rounded v-bind="props">
                          <span :style="fontSm">?</span>
                        </v-chip>
                      </template>
                    </v-tooltip>
                  </div>
                </div>
                <QuestionMathJax
                  :style="fontBase"
                  :text="question?.main_text"
                  class="mt-[12px] mb-[20px]"
                />
                <template v-if="question.type === QuestionType.OBJECTIVE">
                  <ObjectiveComponent
                    :alternatives="question.alternatives"
                    :answers="question.answers"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.MATRIX">
                  <MatrixComponent
                    :answers="question.answers"
                    :columns="question.columns"
                    :rows="question.rows"
                    @answer="
                      answer(subFormQuestions.subFormId, question.id, $event, QuestionType.MATRIX)
                    "
                  />
                </template>
                <template v-if="question.type === QuestionType.TRUE_FALSE">
                  <TrueFalseComponent
                    :alternatives="question.alternatives"
                    :answers="question.answers"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.OPEN_ENDED">
                  <OpenEndedComponent
                    :answer="question.answers ? question.answers[0] : ''"
                    :params="question.params"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.ESSAY">
                  <EssayComponent
                    :answer="question.answers ? question.answers[0] : ''"
                    :params="question.params"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.MULTIPLE_CHOICE">
                  <MultipleChoiceComponent
                    :alternatives="question.alternatives"
                    :answers="question.answers"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.SLIDER">
                  <SliderComponent
                    :answer="question.answers ? question.answers[0] : 0"
                    :params="question.params"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.SORTING">
                  <SortingComponent
                    :alternatives="question.alternatives"
                    :answers="question.answers"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.FILL_IN_THE_BLANK">
                  <FillInTheBlankComponent
                    :answers="question.answers"
                    :wordings="question.wordings"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
                <template v-if="question.type === QuestionType.CONNECT_THE_DOTS">
                  <ConnectTheDotsComponent
                    :alternatives="question?.alternativeDots || question?.alternativesDots || []"
                    :answers="question.answers"
                    :wordings="question.wordings"
                    @answer="answer(subFormQuestions.subFormId, question.id, $event)"
                  />
                </template>
              </v-main>
            </v-sheet>
          </v-col>
        </template>
        <div class="pt-[20px] pb-[60px] w-full flex justify-end items-center mr-[28px]">
          <v-btn
            class="!border-2 text-none bg-white"
            color="blue"
            variant="outlined"
            @click="revision"
          >
            <span :style="fontSm">Revisar e finalizar</span>
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-col v-if="!mobile" cols="3">
      <div id="questionsSidebarParent" class="w-full">
        <div id="questionsSidebar" class="bg-white rounded-xl p-5 h-[80vh] overflow-y-auto">
          <div v-if="data.application?.author" class="flex justify-between mb-6">
            <div class="flex items-center">
              <v-avatar :image="data.application?.author?.avatar"></v-avatar>
              <div :style="fontLg" class="ml-[12px] font-bold">
                {{ data.application?.author?.name ?? data.application?.author?.email }}
              </div>
            </div>
          </div>
          <div class="flex justify-between text-xs">
            <CompletionInfo
              :percentage="percentage"
              :questions-answered-count="questionsAnsweredCount"
              :questions-count="questionsCount"
            />
          </div>
          <div class="mt-[8px] mb-[24px]">
            <v-progress-linear
              :model-value="percentage"
              color="blue"
              height="6"
              rounded
            ></v-progress-linear>
          </div>
          <div class="overflow-y-auto">
            <v-radio-group v-model="activeQuestion">
              <template
                v-for="subFormQuestions of data?.questions"
                :key="subFormQuestions?.subFormOrder"
              >
                <div
                  v-for="(question, index) in (collect(subFormQuestions?.questions) as any)?.sortBy('order')
                  .all()"
                  :key="question?.id"
                  :class="activeQuestion === question?.id && 'bg-[#D2E7F9]'"
                  class="flex cursor-pointer items-center mb-[12px] rounded-lg py-[8px]"
                  @click="goToQuestion(question?.id)"
                >
                  <v-radio :value="question?.id" style="flex: none !important"></v-radio>
                  <span :style="fontBase" class="font-medium">
                    {{ index + 1 }} - {{ StringUtil.htmlToText(question?.main_text) }}...
                  </span>
                </div>
              </template>
            </v-radio-group>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row v-show="!isShowResolution" no-gutters>
    <v-col cols="12">
      <v-sheet
        class="py-5 px-6 rounded-xl w-full"
      >

        <v-main
          :class="{
            'flex justify-between': !mobile,
            'justify-between': mobile
          }"
        >
          <div class="my-5 font-medium text-xl">
            {{ data.application?.name }}
          </div>
          <div v-if="data.application?.author?.name" class="my-5 flex items-start">
            <div>
              <div :style="fontSm" class="mb-2">Professor</div>
              <div class="flex">
                <v-avatar :image="data.application?.author?.avatar" size="24"></v-avatar>
                <div :style="fontLg" class="ml-[12px] font-bold">
                  {{ data.application?.author?.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="my-5 flex items-start">
            <div>
              <div :style="fontSm" class="mb-2">Tempo utilizado</div>
              <div class="text-[#1C86E3] text-xl font-bold">
                {{ usedTime }}
              </div>
            </div>
          </div>
          <div class="my-5 flex items-start">
            <div>
              <div :style="fontSm" class="mb-2">Tentativas utilizadas</div>
              <div v-if="data.application?.access_limit !== 0" class="text-sm">
                <span class="text-[#1C86E3] text-xl font-bold"
                >{{ data.userApplication?.accessed_times }}/</span
                >{{ data.userApplication?.access_limit }}
              </div>
              <div v-else class="text-sm">
                <span class="text-[#1C86E3] text-xl font-bold">Ilimitado</span>
              </div>
            </div>
          </div>
          <div class="my-5 flex items-start">
            <div>
              <div :style="fontSm" class="mb-2">Questões respondidas</div>
              <div class="text-sm">
                <span class="text-[#1C86E3] text-xl font-bold">{{ questionsAnsweredCount }}/</span
                >{{ questionsCount }}
              </div>
            </div>
          </div>
        </v-main>
      </v-sheet>
    </v-col>
    <v-col cols="12">
      <v-sheet
        class="py-5 px-6 rounded-xl w-full mt-5 mb-5"
      >
        <v-main class="mt-5">
          <div
            v-for="(question, index) of (collect(questionsRevision).sortBy('order').all() as any)"
            :key="question.id"
            :class="index > 0 && 'mt-[24px]'"
          >
            <div class="flex">
              <div class="mr-[12px]">
                <v-chip
                  :style="fontSm"
                  class="!font-bold !text-[#1C86E3]"
                  color="#D2E7F9"
                  rounded
                  variant="elevated"
                >{{ index + 1 }}
                </v-chip>
              </div>
              <div class="mr-[12px]">
                <v-chip
                  :style="fontSm"
                  class="!font-bold !text-[#1C86E3]"
                  color="#D2E7F9"
                  rounded
                  variant="elevated"
                >{{ getQuestionType(question.type) }}
                </v-chip>
              </div>
              <div>
                <v-chip
                  v-if="!question.answers"
                  :style="fontSm"
                  class="!font-bold !text-[#F5260A]"
                  color="#FDD4CE"
                  rounded
                  variant="elevated"
                >Não respondida
                </v-chip>
              </div>
            </div>
            <div :style="fontBase" class="mt-[12px]">
              <QuestionMathJax :text="question?.main_text" />
            </div>
            <div v-if="question.answers" class="pt-3">
              <QuestionResolutionReview :question="question" />
            </div>
          </div>
          <div class="flex justify-end pt-3">
            <v-btn
              class="!border-2 text-none bg-white mr-[20px]"
              color="blue"
              variant="outlined"
              @click="isShowResolution = true"
            >
              <span :style="fontSm">Retornar a prova</span>
            </v-btn>
            <v-btn class="!border-2 text-none bg-white" color="blue" @click="endApplication">
              <span :style="fontSm">Finalizar</span>
            </v-btn>
          </div>
        </v-main>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import {
  checkUserApplicationAccessLimit,
  endApplicationAndSave,
  finishApplicationWhenTimeIsOver,
  getApplicationQuestions,
  getPercentageService,
  getQuestionsAnsweredCountService,
  getQuestionsCountService,
  getQuestionsOnReview,
  getTimeSpentFromQuestionsInMinutes,
  saveTimeSpent,
  updateTimeSpentOnQuestion,
  updateUserApplication
} from '@/services/application.service'
import { computed, inject, onMounted, onUnmounted, type Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getQuestionDescription, getQuestionType, updateAnswer } from '@/services/question.service'
import { QuestionType } from '@/enums/question-type.enum'
import MatrixComponent from '@/components/question/MatrixComponent.vue'
import ObjectiveComponent from '../question/ObjectiveComponent.vue'
import TrueFalseComponent from '../question/TrueFalseComponent.vue'
import OpenEndedComponent from '../question/OpenEndedComponent.vue'
import MultipleChoiceComponent from '../question/MultipleChoiceComponent.vue'
import SliderComponent from '../question/SliderComponent.vue'
import SortingComponent from '../question/SortingComponent.vue'
import FillInTheBlankComponent from '../question/FillInTheBlankComponent.vue'
import ConnectTheDotsComponent from '../question/ConnectTheDotsComponent.vue'
import QuestionResolutionReview from './QuestionResolutionReview.vue'
import collect from 'collect.js'
import moment from 'moment'
import { useTimerCounterStore } from '@/stores/counter'
import CompletionInfo from './CompletionInfo.vue'
import { useFontZoomStore } from '@/stores/font'
import { getBrazilianDate } from '@/utils/date'
import { useDisplay } from 'vuetify'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '@/config/firebase'
import { useModalStore } from '@/stores/modal'
import QuestionMathJax from '../question/QuestionMathJax.vue'
import { useLoadingStore } from '@/stores/loading'
import { useConfettiStore } from '@/stores/confetti'
import { useSnackbarStore } from '@/stores/snakebar'
import EssayComponent from '@/components/question/EssayComponent.vue'
import { ApplicationStatus } from '@/enums/application-status.enum'
import StringUtil from '../../utils/string.util'

const { mobile } = useDisplay()
const route = useRoute()
const router = useRouter()
const modalStore = useModalStore()
const loadingStore = useLoadingStore()
const confettiStore = useConfettiStore()
const snackbarStore = useSnackbarStore()

const activeQuestion = ref<string>('')
const data = ref({} as any)
const percentage = ref(null as any)
const questionsCount = ref(null as any)
const questionsAnsweredCount = ref(null as any)
const counter = ref(0)
const timeSpent = ref({} as any)
const questionsRevision = ref([] as any)
let timerId: any = null
let saveTimerSpentId: any = null

const usedTime = computed(() => {
  return moment(useTimerCounterStore().getCounterInMilliseconds).format('mm:ss')
})
const fontSm = computed(() => useFontZoomStore().getFontSm)
const fontBase = computed(() => useFontZoomStore().getFontBase)
const fontLg = computed(() => useFontZoomStore().getFontLg)
const fontXl = computed(() => useFontZoomStore().getFontXl)

const isShowResolution = inject('isShowResolution') as Ref

onMounted(async () => {
  try {
    loadingStore.startLoading()
    clearInterval(timerId)
    data.value = await getApplicationQuestions(route.params.applicationId as string)
    await hasOpenEndedQuestion()

    const isAccessLimitValid = await checkUserApplicationAccessLimit(data.value.userApplication.id)

    if (!isAccessLimitValid) {
      modalStore.sendModal(
        'A avaliação foi encerrada automaticamente por exceder o limite de acesso.',
        1,
        'Finalizar'
      )
      return router.push({ name: 'dashboard' })
    }

    if (
      !data?.value ||
      ![ApplicationStatus.NOT_STARTED, ApplicationStatus.IN_PROGRESS].includes(
        data.value?.userApplication?.status
      )
    ) {
      return router.push({ name: 'dashboard' })
    }

    onSnapshot(
      doc(
        db,
        'users',
        data.value.userApplication.user_id,
        'applications',
        data.value.userApplication.id
      ),
      (doc) => {
        data.value.userApplication = doc.data()

        const { duration, started_at, time_spent } = data.value.userApplication
        const finishesAt = getBrazilianDate(moment.unix(started_at?.seconds)).add(
          duration,
          'minutes'
        )

        if (time_spent) {
          useTimerCounterStore().resumeCounter(time_spent)
        } else {
          useTimerCounterStore().startCounter()
        }

        clearInterval(timerId)

        timerId = setInterval(async () => {
          clearInterval(timerId)

          if (duration) {
            const diff = moment.duration(finishesAt.diff(moment()))

            if (diff?.hours() <= 0 && diff?.minutes() <= 0 && diff?.seconds() <= 0) {
              await finishApplicationWhenTimeIsOver(route.params.applicationId as string)
              await router.push({ name: 'dashboard' })
            }
          }
        }, 1000)
      }
    )

    percentage.value = getPercentage(data.value?.questions)
    questionsCount.value = getQuestionsCount(data.value?.questions)
    questionsAnsweredCount.value = getQuestionsAnsweredCount(data.value?.questions)

    startSaveTimerSpent()

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.4
    })

    data.value.questions.forEach((subFormQuestions: any) => {
      subFormQuestions.questions.forEach((question: any) => {
        const el = document.getElementById(question.id)

        if (el) {
          observer?.observe(el)
        }
      })
    })
  } catch(e) {
    console.log(e)
  }
  finally {
    loadingStore.stopLoading()
  }
})

onUnmounted(() => {
  clearInterval(timerId)
  stopSaveTimerSpent()
  window.removeEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))
  window.removeEventListener('scroll', () =>
    handleScroll('questionsSidebar', 'questionsSidebarParent', 60, 'mt-[108px]')
  )
})

watch(activeQuestion, async (oldValue: string, newValue: string) => {
  setInterval(() => {
    counter.value++
  }, 1000)

  if (oldValue !== newValue) {
    await updateTimeSpentOnQuestion(route.params.applicationId as string, oldValue, counter.value)
    counter.value = 0
  }
})

watch(isShowResolution, async (newValue: boolean) => {
  if (!newValue) {
    questionsRevision.value = await getQuestionsOnReview(route.params.applicationId as string)
    const time = (await getTimeSpentFromQuestionsInMinutes(
      route.params.applicationId as string
    )) as number
    const minutes = Math.floor(time / 60)
    timeSpent.value = {
      minutes,
      seconds: time - minutes * 60
    }
    await updateTimeSpentOnQuestion(
      route.params.applicationId as string,
      activeQuestion.value,
      counter.value
    )
    counter.value = 0
  }
})

const getPercentage = (questions: any) => {
  const questionsData = collect(questions).pluck('questions')
  const questionsAnswered = questionsData
    .map((questions: any) => {
      return questions.filter((question: any) => {
        if (!question?.answers) return false
        return question?.answers?.length > 0
      }).length
    })
    .sum()

  return Number(
    (Number(questionsAnswered) /
      Number(questionsData.map((questions: any) => questions?.length).sum())) *
    100
  ).toFixed(2)
}

const getQuestionsCount = (questions: any) => {
  return collect(questions)
    .pluck('questions')
    .map((questions: any) => questions?.length)
    .sum()
}

const getQuestionsAnsweredCount = (questions: any) => {
  return collect(questions)
    .pluck('questions')
    .map((questions: any) => {
      return questions?.filter((question: any) => {
        if (!question?.answers) return false
        return question?.answers?.length > 0
      }).length
    })
    .sum()
}

const answer = async (subFormId: string, questionId: string, answer: any, type?: QuestionType) => {
  const { user_ref, form_ref, id } = data.value.userApplication

  await updateAnswer(user_ref, form_ref, id, subFormId, questionId, answer, type)

  percentage.value = await getPercentageService(route.params.applicationId as string)
  questionsCount.value = await getQuestionsCountService(route.params.applicationId as string)
  questionsAnsweredCount.value = await getQuestionsAnsweredCountService(
    route.params.applicationId as string
  )
}

const endApplication = async () => {
  try {
    loadingStore.startLoading()
    clearInterval(timerId)
    stopSaveTimerSpent()
    useTimerCounterStore().stopCounter()
    const timeApplicationSpent = useTimerCounterStore().getCounter
    await endApplicationAndSave(
      route.params.applicationId as string,
      data.value.application.id,
      timeApplicationSpent
    )
    await router.push({ name: 'dashboard' })

    confettiStore.startConfetti()
    setTimeout(() => confettiStore.stopConfetti(), 3000)
  } catch (e) {
    console.log(e)
    snackbarStore.showSnackbar(
      ['Ah não! Não conseguimos terminar a avaliação. Que tal tentar mais uma vez?'],
      'error'
    )
  } finally {
    loadingStore.stopLoading()
  }
}

const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      activeQuestion.value = entry.target?.id ?? ''
    }
  })
}

const revision = async () => {
  activeQuestion.value = ''
  isShowResolution.value = false
}

const startSaveTimerSpent = () => {
  stopSaveTimerSpent()
  saveTimerSpentId = setInterval(async () => {
    const time = useTimerCounterStore().getCounter
    const applicationId = route.params.applicationId as string
    await saveTimeSpent(applicationId, time)
  }, 60 * 1000)
}

const stopSaveTimerSpent = () => clearInterval(saveTimerSpentId)

const hasOpenEndedQuestion = async () =>
  await updateUserApplication(
    data.value.userApplication.id,
    data.value.questions?.[0]?.questions?.some(
      (question: any) => question.type === QuestionType.OPEN_ENDED.toString()
    )
  )

const goToQuestion = (questionId: string) => {
  activeQuestion.value = questionId
  const element = document.getElementById(questionId)
  if (element) {
    const offset = 160 // Compensa o deslocamento da barra fixa no topo
    window.scrollTo({
      top: element.offsetTop - offset,
      behavior: 'smooth' // Habilita a rolagem suave
    })
  }
}

const handleScroll = (
  elementId: string,
  parentElementId: string,
  offset: number,
  additionalClass: string = ''
) => {
  const element = document.getElementById(elementId) as HTMLElement
  const parentElement = document.getElementById(parentElementId) as HTMLElement

  if (!element) return

  if (window.scrollY > offset) {
    element.classList.add('position-fixed')
    if (additionalClass) element.classList.add(additionalClass)
    element.style.width = `${parentElement.offsetWidth}px`
    return
  }

  element.classList.remove('position-fixed')
  if (additionalClass) element.classList.remove(additionalClass)
  element.style.width = ''
}

window.addEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))
window.addEventListener('scroll', () =>
  handleScroll('questionsSidebar', 'questionsSidebarParent', 60, 'mt-[108px]')
)
</script>

<style scoped>
.shadow-sheet {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.02);
}
</style>
