import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/dashboard/DashboardView.vue'
import OrganizationsListView from '@/views/organization/OrganizationsListView.vue'
import ResolutionView from '@/views/resolution/ResolutionView.vue'
import FeedbackView from '@/views/feedback/FeedbackView.vue'
import { auth } from '@/config/firebase'
import { signInWithCustomToken } from '@firebase/auth'
import { getUserData, getUserOrganizations } from '@/services/user.service'
import { logout } from '@/services/auth.service'
import { useLoadingStore } from '@/stores/loading'
import { getAuth } from 'firebase/auth'
import { setActiveOrganization } from '@/services/organization.service'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/auth/check-status',
      name: 'auth',
      component: () => import('../views/auth/AuthView.vue')
    },
    {
      path: '/auth/login',
      name: 'login',
      meta: {
        isForDev: true
      },
      component: () => import('../views/auth/LoginDevView.vue')
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: OrganizationsListView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/',
      name: 'dashboard',
      component: DashboardView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/resolution/:applicationId',
      name: 'resolution',
      component: ResolutionView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/feedback/:applicationId',
      name: 'feedback',
      component: FeedbackView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

router.beforeEach(async (to) => {
  useLoadingStore().startLoading()

  if (to?.query?.customToken) {
    try {
      await signInWithCustomToken(getAuth(), to.query.customToken as string)
      const user = (await getUserData())?.data()
      if (!user?.active_organization?.id) {
        await router.push({ name: 'organizations' })
        return
      }
      await setActiveOrganization(user?.active_organization?.id)
      window.location.href = window.location.href?.split('?')?.[0]
    } catch {
      location.href = import.meta.env.VITE_LOGIN_URL
    }
  }

  let user = null

  if (to.meta.isForDev && import.meta.env.VITE_ENVIRONMENT !== 'dev') {
    location.href = import.meta.env.VITE_LOGIN_URL
  }

  try {
    user = await new Promise((resolve, reject) => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const firstOrganization = await getUserOrganizations()

          if (!firstOrganization.length) {
            reject('Usuário não tem acesso a nenhuma instituição.')
            await logout()
          }

          resolve(user)
        } else {
          reject('Usuário não está autenticado.')
        }
      })
    })
  } catch (e) {
    console.log(e)
  }

  if (to.meta.requiresAuth && !user) {
    location.href = import.meta.env.VITE_LOGIN_URL
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0)
  useLoadingStore().stopLoading()
})

export default router
