import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue'
import { vLimitCharacters } from './directives/limitCharacters'
import router from './router'
import { md3 } from 'vuetify/blueprints'
import { db, firebaseApp, setup } from './config/firebase'
import * as Sentry from '@sentry/vue'

// Themes
import { VueFire } from 'vuefire'

// Components
import VueMathjax from 'vue-mathjax-next'
import { addDoc, collection } from 'firebase/firestore'
import { polyfill } from 'seamless-scroll-polyfill'

polyfill()
setup()

const app = createApp(App)

const pruvoLightTheme = {
  dark: false,
  colors: {
    primary: '#1C86E3',
    secondary: '#1BE477',
    'custom-light-blue-gray': '#EAF2F6',
    background: '#EAF2F6'
  }
}

const vuetify = createVuetify({
  components,
  directives,
  blueprint: md3,
  theme: {
    defaultTheme: 'pruvoLightTheme',
    themes: {
      pruvoLightTheme
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
})

Sentry.init({
  app,
  dsn: String(import.meta.env.VITE_SENTRY_DSN),
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /pruvo\.app/gm],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1
})

app
  .use(vuetify)
  .use(createPinia())
  .use(router)
  .use(VueFire, { firebaseApp })
  .use(VueMathjax)
  .use(polyfill)
app.directive('limitCharacters', vLimitCharacters)

app.mount('#app')

app.config.errorHandler = async (error, vm, info) => await registerLog(error, info, 'ERROR')

app.config.warnHandler = async (error, vm, info) => await registerLog(error, info, 'WARN')

async function registerLog(error: any, info: string, severity: 'ERROR' | 'WARN') {
  await addDoc(collection(db, 'loggings'), {
    severity,
    error:
      typeof error === 'string'
        ? error
        : {
            message: error?.message,
            stack: error?.stack
          },
    info,
    created_at: new Date()
  })
}
