import { db } from "@/config/firebase"
import { doc, getDoc } from "firebase/firestore"

export const getProviderById = async (id: string) => {
    const ref = doc(
        db,
        'providers',
        id
    )
    const snapshot = await getDoc(ref)
    return snapshot.data()
}