import { auth, db } from '@/config/firebase'
import LevelEnum from '@/enums/level.enum'
import { signInWithCustomToken, signOut } from '@firebase/auth'
import { collectionGroup, getDocs, limit, query, where } from 'firebase/firestore'

export const loginWithToken = async (token: string) => {
  return await signInWithCustomToken(auth, token)
}

export const getPersonDataOnActiveOrganization = async (): Promise<{
  name: string
  logo: string
  email: string
}> => {
  const q = query(
    collectionGroup(db, 'people'),
    where('organization_id', '==', localStorage.getItem('activeOrganization')),
    where('level', '==', LevelEnum.STUDENT),
    where('email', '==', auth?.currentUser?.email),
    limit(1)
  )
  const snapshot = await getDocs(q)
  return snapshot?.docs?.[0]?.data() as {
    name: string
    logo: string
    email: string
  }
}

export const checkAuthStatus = async () => {
  const baseApi = import.meta.env.VITE_BASE_API
  const response = await fetch(`${baseApi}/authStatus`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return await response.json()
}

export const logout = async () => {
  await signOut(auth)
  localStorage.clear()
  location.href = import.meta.env.VITE_LOGIN_URL
}
