import { db } from '@/config/firebase'
import type { Organization } from '@/models/organization.model'
import collect from 'collect.js'
import { DocumentReference, doc, getDoc } from 'firebase/firestore'
import { clearActiveOrganizationOnUser, setActiveOrganizationOnUser } from './user.service'
import { useRouter } from 'vue-router'

export const getOrganizationsFromArrayOfRefs = async (
  organizationsRef: DocumentReference[]
): Promise<Organization[]> => {
  return (await Promise.all(
    collect(organizationsRef)
      .map(async (organizationRef: DocumentReference) => {
        return (await getDoc(organizationRef)).data()
      })
      .collapse()
      .all()
  )) as Organization[]
}

export const setActiveOrganization = async (organizationId: string) => {
  if (!await isOrganizationExists(organizationId)) {
    const router = useRouter()
    localStorage.clear()
    await clearActiveOrganizationOnUser()
    return router.push({ name: 'organizations'})
  }

  localStorage.setItem('activeOrganization', organizationId)
  setActiveOrganizationOnUser(organizationId)
}

const isOrganizationExists = async (organizationId: string) => {
  try {
    return (await getDoc(doc(db, "organizations", organizationId))).exists()
  } catch {
    return false
  }
}

export const getActiveOrganizationData = async (): Promise<Organization> => {
  const activeOrganizationId = localStorage.getItem('activeOrganization')
  if (!activeOrganizationId) {
    throw new Error('No active organization')
  }
  const activeOrganization = (
    await getDoc(doc(db, 'organizations', activeOrganizationId))
  ).data() as Organization
  return activeOrganization
}
