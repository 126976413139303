<template>
  <svg
    class="mr-2"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#1BE477" height="19" rx="9.5" width="19" x="0.5" y="0.5" />
    <path
      d="M14.0313 4.66663L16.6667 7.54996L8.2 15.3333L3.33333 10.4533L6.09067 7.68863L8.316 9.91996L14.0313 4.66663Z"
      fill="#FAFDFE"
    />
    <rect height="19" rx="9.5" stroke="#1BE477" width="19" x="0.5" y="0.5" />
  </svg>
</template>
